<template>
  <div class="award-ceremony">
    <header class="award-header">
      <h1>首届“百花奖”<br>颁奖盛典</h1>
    </header>
    <section class="award-background" style="margin-top: 22vh;">
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/backLook.png" alt="" />
      <div class="backlook">
        <div style="width: 100%;display: flex;margin-top: 1rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/backjies.png"
            style="width: 7.4rem;height: auto;" alt="">
        </div>
        <div style="color: #F86B0B;margin-top: .4rem;">一片孕育梦想、见证蜕变、女性精神宣传平台</div>
        <div>
          中国妈妈文化艺术节，受《星光大道》节目启发由安道文化传媒旗下知名品牌《媛梦星光大道》推出的一档让素人女性圆梦舞台的大型名媛选秀栏目，致力于携手平凡妈妈们跨越界限，登上专业的艺术殿堂，让每一个梦想照进现实，绽放璀璨星光。
        </div>
      </div>
    </section>

    <section class="award-background" style="margin-top: 0px;">
      <div class="backlook" style="flex: 1;margin: 0 .6rem 0 1.4rem;">
        <div style="width: 100%;display: flex;margin-top: .7rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/saishizbf.png"
            style="width: 9rem;height: auto;" alt="">
        </div>
        <div style="color: #F86B0B;margin-top: .4rem;">安道传媒</div>
        <div>
          活动由安道文化传媒主办，安道文化传媒是一家在本地拥有多年文化活动策划经验的机构，自2021年在中国雄安新区启动以来，《媛梦星光大道》已逐渐发展成为国内首屈一指的女性选秀平台，其独特的赛制、丰富的才艺展示以及全方位的明星打造模式，为无数平凡女性提供了实现梦想的舞台。
        </div>
      </div>
      <div class="videoback" style="margin-top: 1rem;">
        <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/videoBorder.png" @click="play" alt="">
        <video src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/andao.mp4" ref="video"
          poster="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/andaoCover.png" id="video"></video>

        <img v-if="!videos" src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/playIcon.png"
          style="width: 4rem !important;height: 4rem !important;position: absolute;left: 47% !important;top: 50% !important;margin-left: -2rem;margin-top: -2rem;"
          alt="">
      </div>
    </section>


    <section class="rules">
      <div>
        <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/rulesguize.png" style="width: 7.6rem;"
          alt="">
      </div>
      <p style="color: #F86B0B;margin-bottom: 0;margin-top: .6rem;">比赛规则</p>
      <p style="color: #080806;margin: 0;padding: 0 1.4rem;">本次活动为个人赛，分为歌唱、舞蹈、乐器演奏、杂技魔术等多个才艺类别，每位选手只能选择一个类别参赛”。</p>
      <p style="color: #080806;margin: 0;padding: 0 1.4rem;">
        比赛将由专业评委团进行打分，评委将从选手的专业技能（占60%）、舞台表现力（占30%）和节目创意（占10%）三个方面进行综合评估。同时，现场观众投票也将作为重要参考，观众投票占总分的30%。</p>

      <p style="color: #F86B0B;margin-bottom: 0;margin-top: 1.4rem;">比赛流程</p>
      <p style="color: #080806;margin: 1.4rem 0 0 0;padding: 0 1.4rem;">初选-复赛-决赛</p>
    </section>
    <section class="prizes">
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/jiangxiang.png" style="width: 9rem;"
        alt="">
      <ul>
        <li><img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a1.png" style="width: 74%;" alt="">
        </li>
        <li><img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a2.png" style="width: 74%;" alt="">
        </li>
        <li><img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a3.png" style="width: 74%;" alt="">
        </li>
        <li><img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a4.png" style="width: 74%;" alt="">
        </li>
        <li><img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a5.png" style="width: 74%;" alt="">
        </li>
      </ul>
    </section>



    <section class="award-background">
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/zhuanyepingwei.png" alt="" />
      <div class="backlook">
        <div style="width: 100%;display: flex;margin-top: 2rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/zypwt.png"
            style="width: 7.4rem;height: auto;" alt="">
        </div>
        <div style="color: #F86B0B;margin-top: .4rem;">专业评审</div>
        <div>
          本次比赛邀请了多位业内知名人士担任评委，著名明星坐镇。以专业的眼光和评审，确保比赛的专业和公平公正。
        </div>
      </div>
    </section>

    <section class="award-background" style="padding: 0 1rem;">
      <div class="backlook" style="flex: 1;margin: 0 .6rem 0 1.4rem;">
        <div style="width: 100%;display: flex;margin-top: 2rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/gaojiwuts.png"
            style="width: 9rem;height: auto;" alt="">
        </div>
        <div style="color: #F86B0B;margin-top: .4rem;">央视级灯光舞美</div>
        <div>
          大型LED屏幕作为背景，可根据选手表演内容实时切换绚丽多彩的场景。现场配备了专业级的音响系统，确保声音的高保真度和立体感。为选手打造出美轮美奂的舞台氛围。
        </div>
      </div>
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/gaojiwutai.png" alt="" />
    </section>

    <div class="hdty">
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/hudongtiyan.png"
        style="width: 100%;height: auto;" alt="">
      <div class="content">
        <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/hdtit.png" alt="">
        <div>
          活动中为观众设置互动环节在比赛过程中，观众可以通过手机扫描现场二维码，进入官方投票平台为喜爱的选手投票。每一轮投票结束后，我们将在现场抽取幸运观众，送上精美礼品，包括签名照、演出门票、周边产品等
        </div>
      </div>
    </div>



    <section class="registration">
      <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/signtit.png" style="width: 10rem;" alt="">
      <p style="color: #F86B0B;margin-bottom: 0;margin-top: .6rem;">报名方式</p>
      <p style="color: #FFFFFF;margin: 0;">搜索“媛梦之家”微信小程序，即可进行报名...</p>
      <div style="width: 4.6rem;height: 4.6rem;background-color: #FFFFFF;border-radius: 10rem;margin: 1rem auto 0;">
        <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxg.jpg"
          style="width: 100%;height: 100%;border-radius: 100%;" alt="">
      </div>
      <p style="color: #F86B0B;margin-bottom: 0;margin-top: .6rem;">咨询渠道</p>
      <p style="color: #FFFFFF;margin: 0;"> 搜索“媛梦星光大道”或“媛梦星光”<br>
        各大平台账号了解中国妈妈文化艺术节-媛梦星光“百花奖”<br>
        颁奖盛典最新进程资讯！
      <div style="margin: 1rem auto 0;display: flex;justify-content: center;">
        <div style="width: 4.6rem;height: 4.6rem;background-color: #FFFFFF;border-radius: 10rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png"
            style="width: 100%;height: 100%;border-radius: 100px;" alt="">
        </div>
        <div style="width: 4.6rem;height: 4.6rem;background-color: #FFFFFF;border-radius: 10rem;margin-left: 2rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png"
            style="width: 100%;height: 100%;border-radius: 100px;" alt="">
        </div>
      </div>
      </p>
    </section>


    <section class="award-background" style="margin-top: 2rem;">
      <div class="backlook" style="flex: 1;margin: 0 .6rem 0 1.4rem;">
        <div style="width: 100%;display: flex;margin-top: 2rem;">
          <img src="https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/kechengsjdd.png"
            style="width: 9rem;height: auto;" alt="">
        </div>
        <div style="color: #FFF;margin-top: .4rem;">2024年12月28日</div>
        <div>
          活动地点：云南省昆明市呈贡文体活动中心剧院（地址：云南省昆明市呈贡区富康路云南大学附属中学东南侧约200米） 附近有停车场方便停车
        </div>
      </div>
      <div class="videoback">
        <img @click="navToMap(lat, lng, partnerAddress)" style="border-radius: 2rem;height: 12rem !important;"
          src="https://restapi.amap.com/v3/staticmap?location=102.807627,24.882399&zoom=16&size=750*500&markers=mid,0x0000FF,:116.481485,39.990464&key=4c906a71ed596bd5bf7f047426562b4c"
          alt="">
      </div>
    </section>




  </div>
</template>


<script>
import share from "../tool/share.js";
import wx from "weixin-js-sdk";
import axios from "axios";


export default {
  name: "index",
  components: {
  },
  data() {
    return {
      videos: false,
      lng: 102.807627,
      coverImageUrl: '',
      lat: 24.882399,
      partnerAddress: '云南省昆明市呈贡区富康路云南大学附属中学东南侧约200米'
    };
  },
  created() {
    this.editPageTitle('颁奖盛典')
    //分享
    // this.getShareInfo();
    this.wxShare();
  },
  methods: {
    getShareInfo() {
      var that = this;
      var winUrl = window.location.href.split("#")[0];
      // 配置分享, 以下两个参数是我自己生成，传给后端的
      var timestamp = Date.parse(new Date()) / 1000;
      var nonceStr = that.createNonceStr();
      let url = window.location.href;
      let urls = "http://test.beijingcbl.com";

      axios
        .get(
          `https://htb.myxgdd.com/prod-api/external/common/api/getTicket`
        )
        .then((res1) => {
          console.log(res1.data.msg);
          let params = {
            // jsapiTicket: res1.data.msg,
            url: urls,
            // url: encodeURIComponent(location.href.split('#')[0])
          };
          console.log(params,999);
          
          axios
            .get(
              `https://htb.myxgdd.com/prod-api/external/common/api/generateSignature`,
              {
                params: params,
              }
            )
            .then((res) => {
              console.log(res);
              
              wx.config({
                debug: true,
                appId: 'wxd24886963318acd9', // 必填，公众号的唯一标识
                timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
                signature: res.data.data.signature, // 必填，签名
                jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              });
              // wx.ready(function () {
              //   //分享到朋友圈  需在用户可能点击分享按钮前就先调用
              //   wx.updateTimelineShareData({
              //     title: '标题', // 分享时的标题
              //     desc: '描述', // 分享描述
              //     link: '12313123', // 分享时的链接
              //     imgUrl: 'https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png', // 分享图标
              //     success: function () {
              //       // 设置成功
              //       console.log("分享到朋友圈成功");
              //     },
              //     cancel: function () {
              //       // 取消设置
              //       console.log("分享到朋友圈取消");
              //     },
              //     fail: function () {
              //       console.log("分享朋友圈失败");
              //     },
              //   });
              //   // 分享给朋友的设置
              //   wx.updateAppMessageShareData({
              //     title: '标题', // 分享时的标题
              //     desc: '描述', // 分享描述
              //     link: '12313123', // 分享时的链接
              //     imgUrl: 'https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png',
              //     success: function () {
              //       // 设置成功
              //       console.log("分享给朋友成功");
              //     },
              //     cancel: function () {
              //       // 设置失败
              //       console.log("分享给朋友失败");
              //     },
              //     fail: function () {
              //       console.log("分享朋友圈失败");
              //     },
              //   });
              // });
            });
        }
        );
    },
    createNonceStr() {
      return Math.random().toString(36).substr(2, 15);
    },
    //    进入全屏
    launchFullscreen(video) {
      const refsArr = this.$refs.video
      console.log(222)
      let currElement = document.getElementById('video')
      console.log(currElement);

      if (currElement.requestFullscreen) {
        currElement.requestFullscreen()
      } else if (currElement.mozRequestFullScreen) {
        currElement.mozRequestFullScreen()
      } else if (currElement.msRequestFullscreen) {
        currElement.msRequestFullscreen()
      } else if (currElement.oRequestFullscreen) {
        currElement.oRequestFullscreen()
      } else if (currElement.webkitRequestFullscreen) {
        currElement.webkitRequestFullScreen()
      }

    },
    //    退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.oRequestFullscreen) {
        document.oCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    //分享
    wxShare() {
      let shareData = {
        title: "媛梦星光“百花奖”颁奖盛典",
        desc: "中国妈妈艺术文化节—2024媛梦星光“百花奖”颁奖盛典",
        imgUrl:
          "https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/fengmian.png",
      };
      share.wxShare(shareData);
    },

    /**
     * 打开高德或百度地图
     * @param {*} latitude
     * @param {*} longitude
     * @param {*} name  导航目的地名称
     * @param {*} type 1 百度地图 2 高德地图
     */
    navToMap(latitude = 0, longitude = 0, name = '目的地', type = 2) {
      let url
      let lat, long
      const u = navigator.userAgent
      //判断ios
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      //判断Android
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
      if (type == 1) {
        //百度地图 把获取到的非百度坐标转为百度坐标
        lat = latitude
        long = longitude
      } else if (type == 2) {
        //高德地图 把获取到的非GCJ-02坐标转为GCJ-02(火星坐标)
        lat = latitude
        long = longitude
      }
      if (isAndroid) {
        switch (type) {
          case 1: //百度地图
            url = `http://api.map.baidu.com/marker?location=${lat},${long}&title=${name}&content=${name}&output=html`
            break
          case 2: //高德地图
            url = `androidamap://viewMap?sourceApplication=appname&poiname=${name}&lat=${lat}&lon=${long}&dev=0`
            break
          default:
            break
        }
      } else if (isIOS) {
        switch (type) {
          case 1: //百度地图
            url = `http://api.map.baidu.com/marker?location=${lat},${long}&title=${name}&content=${name}&output=html`
            break
          case 2: //高德地图
            url = `http://uri.amap.com/marker?position=${long},${lat}&name=${name}&src=mypage&coordinate=gaode&callnative=00`
            break
          default:
            break
        }
      } else {
        switch (type) {
          case 1: //百度地图
            url = `http://api.map.baidu.com/marker?location=${lat},${long}&title=${name}&content=${name}&output=html`
            break
          case 2: //高德地图
            url = `http://uri.amap.com/marker?position=${long},${lat}&name=${name}&src=mypage&coordinate=gaode&callnative=00`
            break
          default:
            break
        }
      }
      if (!this.validatenull(url)) {
        window.location.href = url
      }
    },
    validatenull(val) {
      if (typeof val === 'boolean') {
        return false
      }
      if (typeof val === 'number') {
        return false
      }
      if (val instanceof Array) {
        if (val.length === 0) return true
      } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true
      } else {
        if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
        return false
      }
      return false
    },
    play() {
      if (!this.videos) {
        this.videos = true
        this.launchFullscreen()
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
        this.videos = false
      }
    },
    editPageTitle(name) {
      document.title = name
      const i = document.createElement('iframe')
      i.src = '/favicon.ico'
      i.style.display = 'none'
      i.onload = function () {
        setTimeout(function () {
          i.remove()
        }, 9)
      }
      document.body.appendChild(i)
    },
  },
};
</script>

<style scoped lang="scss">
p,
div {
  font-family: cursive;
}

.award-ceremony {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  background: url(https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/IndexBack.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 16rem;
}

.award-header {
  /* background-color: #8b0000; */
  color: #040000;
  font-weight: bold;
  font-size: 2.2rem;
  padding: 2rem;
  opacity: 0;
  box-shadow: .2rem 0px 1.8rem 0px #040000;
  text-align: center;
}

.award-background {
  // background-color: #f5f5f5;
  padding: 1rem;
  text-align: center;
  display: flex;
  // align-items: center;
}

.backlook {
  margin-left: 1rem;

  h2 {
    font-weight: normal;
    font-size: 2rem;
    color: #FFFFFF;
    text-align: left;
  }

  div {
    font-size: .7rem;
    text-align: left;
    color: #FFFFFF;
    line-height: 1.2rem;
  }

}

.videoback {
  width: 50%;
  position: relative;
  // background: url(https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/videoBorder.png) no-repeat;
  // background-size: 100% 100%;
  display: flex;
  align-items: center;
  height: 12rem;
  justify-content: center;

  img {
    width: 100% !important;
    height: 13.1rem !important;
    max-width: 100% !important;
    position: absolute;
    left: 0;
    top: -.3rem;
    z-index: 99;
  }

  video {
    width: 84%;
    margin-right: 1rem;
    height: 8.2rem;
  }
}

.award-background img {
  max-width: 50%;
  // height: 120px;
}

.rules {
  background: url(https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/saishiback.png) no-repeat;
  background-size: 100% 100%;
  margin: 4rem 1.4rem 0;

  p {
    font-size: 1rem;
  }
}

.organizer-info,
.rules,
.professional-jury,
.stage,
.interaction,
.registration,
.schedule {
  padding: 2rem;
  margin-bottom: 2rem;
  // background-color: white;
  border-radius: .8rem;
  box-shadow: 0 .2rem 。4rem rgba(0, 0, 0, 0.1);

}

.registration p {
  font-size: 1rem;
}

h2 {
  color: #8b0000;
}

ul {
  list-style-type: none;
  padding: 0;
}



.schedule p {
  margin: 0;
}

.hdty {
  width: 90%;
  margin: 3rem auto 0;
  position: relative;

  .content {
    width: 45%;
    position: absolute;
    right: 3rem;
    top: 4rem;
    display: flex;
    flex-wrap: wrap;

    img {
      width: 13rem;
    }

    div {
      width: 100%;
      text-align: left;
      margin-top: 1rem;
      color: #2A140E;
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }
}
</style>
