var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"award-ceremony"},[_vm._m(0),_vm._m(1),_c('section',{staticClass:"award-background",staticStyle:{"margin-top":"0px"}},[_vm._m(2),_c('div',{staticClass:"videoback",staticStyle:{"margin-top":"1rem"}},[_c('img',{attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/videoBorder.png","alt":""},on:{"click":_vm.play}}),_c('video',{ref:"video",attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/andao.mp4","poster":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/andaoCover.png","id":"video"}}),(!_vm.videos)?_c('img',{staticStyle:{"width":"4rem !important","height":"4rem !important","position":"absolute","left":"47% !important","top":"50% !important","margin-left":"-2rem","margin-top":"-2rem"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/playIcon.png","alt":""}}):_vm._e()])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('section',{staticClass:"award-background",staticStyle:{"margin-top":"2rem"}},[_vm._m(9),_c('div',{staticClass:"videoback"},[_c('img',{staticStyle:{"border-radius":"2rem","height":"12rem !important"},attrs:{"src":"https://restapi.amap.com/v3/staticmap?location=102.807627,24.882399&zoom=16&size=750*500&markers=mid,0x0000FF,:116.481485,39.990464&key=4c906a71ed596bd5bf7f047426562b4c","alt":""},on:{"click":function($event){return _vm.navToMap(_vm.lat, _vm.lng, _vm.partnerAddress)}}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"award-header"},[_c('h1',[_vm._v("首届“百花奖”"),_c('br'),_vm._v("颁奖盛典")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"award-background",staticStyle:{"margin-top":"22vh"}},[_c('img',{attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/backLook.png","alt":""}}),_c('div',{staticClass:"backlook"},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":"1rem"}},[_c('img',{staticStyle:{"width":"7.4rem","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/backjies.png","alt":""}})]),_c('div',{staticStyle:{"color":"#F86B0B","margin-top":".4rem"}},[_vm._v("一片孕育梦想、见证蜕变、女性精神宣传平台")]),_c('div',[_vm._v(" 中国妈妈文化艺术节，受《星光大道》节目启发由安道文化传媒旗下知名品牌《媛梦星光大道》推出的一档让素人女性圆梦舞台的大型名媛选秀栏目，致力于携手平凡妈妈们跨越界限，登上专业的艺术殿堂，让每一个梦想照进现实，绽放璀璨星光。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backlook",staticStyle:{"flex":"1","margin":"0 .6rem 0 1.4rem"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":".7rem"}},[_c('img',{staticStyle:{"width":"9rem","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/saishizbf.png","alt":""}})]),_c('div',{staticStyle:{"color":"#F86B0B","margin-top":".4rem"}},[_vm._v("安道传媒")]),_c('div',[_vm._v(" 活动由安道文化传媒主办，安道文化传媒是一家在本地拥有多年文化活动策划经验的机构，自2021年在中国雄安新区启动以来，《媛梦星光大道》已逐渐发展成为国内首屈一指的女性选秀平台，其独特的赛制、丰富的才艺展示以及全方位的明星打造模式，为无数平凡女性提供了实现梦想的舞台。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"rules"},[_c('div',[_c('img',{staticStyle:{"width":"7.6rem"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/rulesguize.png","alt":""}})]),_c('p',{staticStyle:{"color":"#F86B0B","margin-bottom":"0","margin-top":".6rem"}},[_vm._v("比赛规则")]),_c('p',{staticStyle:{"color":"#080806","margin":"0","padding":"0 1.4rem"}},[_vm._v("本次活动为个人赛，分为歌唱、舞蹈、乐器演奏、杂技魔术等多个才艺类别，每位选手只能选择一个类别参赛”。")]),_c('p',{staticStyle:{"color":"#080806","margin":"0","padding":"0 1.4rem"}},[_vm._v(" 比赛将由专业评委团进行打分，评委将从选手的专业技能（占60%）、舞台表现力（占30%）和节目创意（占10%）三个方面进行综合评估。同时，现场观众投票也将作为重要参考，观众投票占总分的30%。")]),_c('p',{staticStyle:{"color":"#F86B0B","margin-bottom":"0","margin-top":"1.4rem"}},[_vm._v("比赛流程")]),_c('p',{staticStyle:{"color":"#080806","margin":"1.4rem 0 0 0","padding":"0 1.4rem"}},[_vm._v("初选-复赛-决赛")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"prizes"},[_c('img',{staticStyle:{"width":"9rem"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/jiangxiang.png","alt":""}}),_c('ul',[_c('li',[_c('img',{staticStyle:{"width":"74%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a1.png","alt":""}})]),_c('li',[_c('img',{staticStyle:{"width":"74%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a2.png","alt":""}})]),_c('li',[_c('img',{staticStyle:{"width":"74%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a3.png","alt":""}})]),_c('li',[_c('img',{staticStyle:{"width":"74%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a4.png","alt":""}})]),_c('li',[_c('img',{staticStyle:{"width":"74%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/a5.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"award-background"},[_c('img',{attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/zhuanyepingwei.png","alt":""}}),_c('div',{staticClass:"backlook"},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":"2rem"}},[_c('img',{staticStyle:{"width":"7.4rem","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/zypwt.png","alt":""}})]),_c('div',{staticStyle:{"color":"#F86B0B","margin-top":".4rem"}},[_vm._v("专业评审")]),_c('div',[_vm._v(" 本次比赛邀请了多位业内知名人士担任评委，著名明星坐镇。以专业的眼光和评审，确保比赛的专业和公平公正。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"award-background",staticStyle:{"padding":"0 1rem"}},[_c('div',{staticClass:"backlook",staticStyle:{"flex":"1","margin":"0 .6rem 0 1.4rem"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":"2rem"}},[_c('img',{staticStyle:{"width":"9rem","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/gaojiwuts.png","alt":""}})]),_c('div',{staticStyle:{"color":"#F86B0B","margin-top":".4rem"}},[_vm._v("央视级灯光舞美")]),_c('div',[_vm._v(" 大型LED屏幕作为背景，可根据选手表演内容实时切换绚丽多彩的场景。现场配备了专业级的音响系统，确保声音的高保真度和立体感。为选手打造出美轮美奂的舞台氛围。 ")])]),_c('img',{attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/gaojiwutai.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hdty"},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/hudongtiyan.png","alt":""}}),_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/hdtit.png","alt":""}}),_c('div',[_vm._v(" 活动中为观众设置互动环节在比赛过程中，观众可以通过手机扫描现场二维码，进入官方投票平台为喜爱的选手投票。每一轮投票结束后，我们将在现场抽取幸运观众，送上精美礼品，包括签名照、演出门票、周边产品等 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"registration"},[_c('img',{staticStyle:{"width":"10rem"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/signtit.png","alt":""}}),_c('p',{staticStyle:{"color":"#F86B0B","margin-bottom":"0","margin-top":".6rem"}},[_vm._v("报名方式")]),_c('p',{staticStyle:{"color":"#FFFFFF","margin":"0"}},[_vm._v("搜索“媛梦之家”微信小程序，即可进行报名...")]),_c('div',{staticStyle:{"width":"4.6rem","height":"4.6rem","background-color":"#FFFFFF","border-radius":"10rem","margin":"1rem auto 0"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","border-radius":"100%"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxg.jpg","alt":""}})]),_c('p',{staticStyle:{"color":"#F86B0B","margin-bottom":"0","margin-top":".6rem"}},[_vm._v("咨询渠道")]),_c('p',{staticStyle:{"color":"#FFFFFF","margin":"0"}},[_vm._v(" 搜索“媛梦星光大道”或“媛梦星光”"),_c('br'),_vm._v(" 各大平台账号了解中国妈妈文化艺术节-媛梦星光“百花奖”"),_c('br'),_vm._v(" 颁奖盛典最新进程资讯！ ")]),_c('div',{staticStyle:{"margin":"1rem auto 0","display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"width":"4.6rem","height":"4.6rem","background-color":"#FFFFFF","border-radius":"10rem"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","border-radius":"100px"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png","alt":""}})]),_c('div',{staticStyle:{"width":"4.6rem","height":"4.6rem","background-color":"#FFFFFF","border-radius":"10rem","margin-left":"2rem"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","border-radius":"100px"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/ymxgdd.png","alt":""}})])]),_c('p')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backlook",staticStyle:{"flex":"1","margin":"0 .6rem 0 1.4rem"}},[_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":"2rem"}},[_c('img',{staticStyle:{"width":"9rem","height":"auto"},attrs:{"src":"https://abc-static.oss-cn-beijing.aliyuncs.com/abc-htb/andao/kechengsjdd.png","alt":""}})]),_c('div',{staticStyle:{"color":"#FFF","margin-top":".4rem"}},[_vm._v("2024年12月28日")]),_c('div',[_vm._v(" 活动地点：云南省昆明市呈贡文体活动中心剧院（地址：云南省昆明市呈贡区富康路云南大学附属中学东南侧约200米） 附近有停车场方便停车 ")])])
}]

export { render, staticRenderFns }