import axios from "axios";
// import { API_1 } from "../conf/api";
export default {
  wxShare: function (shareData) {
    let url = window.location.href.split("#")[0];
    // let urls = "https://omo.myxgdd.com";
    let urls = "https://andao.myxgdd.com";

    let params = {
      url: url,
      // jsapiTicket:res1.data.jsapiTicket
    };
    axios
      .get(
        `https://htb.myxgdd.com/prod-api/external/common/api/generateSignature`,
        {
          params: params,
        }
      )
      .then((res) => {
        // console.log(res.data.data,678);
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名
          jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
        });
        window.wx.ready(function () {
          window.wx.updateTimelineShareData({
            title: shareData.title,
            desc: shareData.desc,
            link: urls,
            imgUrl: shareData.imgUrl,
            trigger: function () {},
            success: function () {},
            cancel: function () {},
          });
          window.wx.updateAppMessageShareData({
            title: shareData.title,
            desc: shareData.desc,
            link: urls,
            imgUrl: shareData.imgUrl,
            trigger: function (res) {},
            success: function (res) {},
            cancel: function (res) {},
            fail: function (res) {},
          });
        });
      })
      .catch((res) => {
        console.log(res);
      });
  },
};
