import Vue from 'vue'
import "swiper/css/swiper.css"
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.prototype.$axios=axios
Vue.use(axios,VueAxios)

//挂载请求
// import {API,GET,POST,uid} from './conf/api'
// Vue.prototype.$API = API;
// Vue.prototype.$GET = GET;
// Vue.prototype.$POST = POST;
// Vue.prototype.uid = uid;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
